.banner{
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url(../Images/bannerbg.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
    /* background-image: url(../Images/bannerbg.jpg); */
}
.text-box{
    width: 90%;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: typewriter 5s steps(7) infinite;
    text-align: center;
        font-family: sans-serif;
        font-weight: 800;
        font-size: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
}